import React, {Component} from "react";
import styled from "styled-components";
import HeaderContent from "./HeaderContent";
import HeaderWrapper from "./HeaderWrapper";
import ProfileDropdown from "./ProfileDropdown";
import GlobalNotifications from "components/common/icons/notifications/GlobalNotifications";
import {withContextConsumer} from "utils/contexts";
import NotificationsContext from "contexts/Notifications";
import PopUpNotification from "components/notification/PopUp/PopUpNotification";
import notificationsType from "consts/notificationsType";
import userTypes from "consts/userTypes";
import {withRouter} from "react-router-dom";
import FileUploadProgress from "components/fileUploadProgress/FileUploadProgress";
import MetaTags from "components/common/metatags/MetaTags";
import {withNamespaces} from "react-i18next";
import {mediaQuery} from "components/common/styled/mediaQuery";
import LogoTransparent from "./LogoTransparent";

const NOTIFICATION_TYPES = {
    chatNotifications: "isOpenChatNotifications",
    allNotifications: "isOpenGlobalNotifications"
};

const LeftContainerWithLogo = styled.div`
    display: flex;
    align-items: center;
    min-width: 37rem;

    ${mediaQuery.phone`
        min-width: unset;
    `}
`;

const RightContainerWithNotifications = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    ${p =>
        p.dir === "ltr"
            ? `
        margin-left: auto;
        margin-right: 0;
    `
            : `
        margin-left: 0;
        margin-right: auto;
    `}

    width: 8rem;

    svg {
        height: 1.2rem;
        cursor: pointer;
    }
`;

const ProfileAndLang = styled.div`
    display: flex;
    align-items: center;
`;

const TopRightCorner = styled.div`
    display: flex;
`;

@withRouter
@withContextConsumer(NotificationsContext.Consumer)
@withNamespaces("translation")
class SmsLayout extends Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
        this.handlerOpenNotifications = this.handlerOpenNotifications.bind(this);
    }

    initState() {
        return {
            isOpenGlobalNotifications: false
        };
    }

    setViewedGroupOfNotifications(notifications) {
        const {setViewed} = this.props;
        const identificators = notifications.filter(notification => !notification.isViewed).map(u => u.id);

        setViewed(identificators);
    }

    handlerOpenNotifications(type) {
        const newState = this.initState();
        const isOpen = NOTIFICATION_TYPES[type];
        newState[isOpen] = true;
        this.setState(newState);
    }

    handlerCloseNotifications(type) {
        const notifications = this.props[type];
        this.setState(this.initState());
        this.setViewedGroupOfNotifications(notifications.headerNotifications);
    }

    render() {
        const {
            history,
            currentUser,
            logout,
            allNotifications,
            newGlobalNotification,
            clearAllByType,
            clearByTypeAndIds,
            i18n
        } = this.props;

        const isAdmin = currentUser.role === userTypes.admin;
        const isTeacher = currentUser.role === userTypes.teacher;
        const isPrincipal = currentUser.role === userTypes.principal;

        return (
            <>
                <HeaderWrapper isSms={true}>
                    <MetaTags path={"/"} />
                    <HeaderContent>
                        <LeftContainerWithLogo>
                            <LogoTransparent />
                        </LeftContainerWithLogo>

                        <TopRightCorner>
                            {!isAdmin && (
                                <RightContainerWithNotifications dir={i18n.dir()}>
                                    <FileUploadProgress />
                                    <PopUpNotification
                                        isSms={true}
                                        handlerOpen={this.handlerOpenNotifications.bind(this)}
                                        handlerClose={this.handlerCloseNotifications.bind(this)}
                                        isOpen={this.state.isOpenGlobalNotifications}
                                        headerNotifications={allNotifications.headerNotifications}
                                        newNotification={newGlobalNotification}
                                        clearAllByType={clearAllByType}
                                        clearByTypeAndIds={clearByTypeAndIds}
                                        type={notificationsType.allNotifications}
                                        arrow={i18n.dir() === "ltr" ? "end" : "absoluteStart"}>
                                        <GlobalNotifications
                                            isSms={true}
                                            active={allNotifications.headerNotifications.some(u => !u.isViewed)}
                                        />
                                    </PopUpNotification>
                                </RightContainerWithNotifications>
                            )}
                            <ProfileAndLang>
                                <ProfileDropdown
                                    id="registeredHeaderProfileDropdown"
                                    user={currentUser}
                                    logout={logout}
                                    isSms={true}
                                />
                            </ProfileAndLang>
                        </TopRightCorner>
                    </HeaderContent>
                </HeaderWrapper>
            </>
        );
    }
}

export default SmsLayout;
