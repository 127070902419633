import routes from "../../consts/routes";

const routesWithoutHeader = [routes.signin.path, routes.signup.path, routes.reset.path];
const routesSMS = [
    routes.mainDashboard.path,
    routes.subjects.path,
    routes.subjectsAdd.path,
    routes.subjectsEdit.path,
    routes.subjectsView.path,
    routes.subjectCurriculums.path,
    routes.subjectCurriculumsAdd.path,
    routes.subjectCurriculumsEdit.path,
    routes.subjectCurriculumsView.path,
    routes.teacherSubjects.path,
    routes.teacherSubjectsAdd.path,
    routes.teacherSubjectsEdit.path,
    routes.teacherSubjectsView.path,
    routes.classes.path,
    routes.classesAdd.path,
    routes.classesEdit.path,
    routes.classesView.path,
    routes.lessons.path,
    routes.lessonsAdd.path,
    routes.lessonsEdit.path,
    routes.lessonsView.path,
    routes.studentAttendances.path,
    routes.studentAttendancesAdd.path,
    routes.studentAttendancesEdit.path, // todo: view not yet
    routes.teacherAttendances.path,
    routes.teacherAttendancesAdd.path,
    routes.teacherAttendancesEdit.path, // todo: view not yet
    routes.schoolBuildings.path,
    routes.schoolBuildingsAdd.path,
    routes.schoolBuildingsEdit.path,
    routes.schoolBuildingsView.path,
    routes.periods.path,
    routes.periodsAdd.path,
    routes.periodsEdit.path,
    routes.periodsView.path,
    routes.schedules.path,
    routes.schedulesAdd.path,
    routes.schedulesEdit.path,
    routes.schedulesView.path,
    routes.smsStudents.path,
    routes.smsStudentsAdd.path,
    routes.smsStudentsEdit.path,
    routes.smsStudentsView.path,
    routes.schedulesView.path,
    routes.feeTypes.path,
    routes.feeTypesAdd.path,
    routes.feeTypesEdit.path,
    routes.feeTypesView.path,
    routes.fees.path,
    routes.feesAdd.path,
    routes.feesEdit.path,
    routes.feesView.path,
    routes.schoolWeekdays.path,
    routes.schoolWeekdaysAdd.path,
    routes.schoolWeekdaysEdit.path,
    routes.schoolWeekdaysView.path
];

function pathToRegex(path) {
    return new RegExp(
        "^" +
        path.replace(/:\w+/g, "([a-zA-Z0-9]+)") + // Replace :id with a capture group
            "$"
    );
}

function matchRoute(currentRoute, routes) {
    for (const route of routes) {
        const routeRegex = pathToRegex(route);
        if (routeRegex.test(currentRoute)) {
            return true; // If there's a match, return true
        }
    }
    return false; // No match found
}

export {routesWithoutHeader, routesSMS, matchRoute};
