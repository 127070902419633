import React, {Component, Suspense} from "react";
import {Route, Switch} from "react-router-dom";
import Loading from "./Loading";
import styled from "styled-components";
import Footer from "./Footer";
import Header from "./Header";
import GlobalModals from "components/globalModals/GlobalModals";
import GlobalToast from "components/globalToast/GlobalToast";
import Scrollbars from "components/common/Scrollbars";
import TawkChat from "components/TawkChat";
import CurrentUser from "contexts/CurrentUser";
import LandingContext from "contexts/Landing";
import routes from "consts/routes";
import {withContextConsumer} from "utils/contexts";
import ChatWidget from "./ChatWidget";
import LecturesDigest from "./lectures/LecturesDigest";

const IndexLoadable = React.lazy(() => import("./Index"));
const RegisterLoadable = React.lazy(() => import("./auth/Register"));
const LoginLoadable = React.lazy(() => import("./auth/Login"));
const ProfileLoadable = React.lazy(() => import("./profile/Profile"));
const ResetPasswordLoadable = React.lazy(() => import("./auth/ResetPassword"));
const CoursesLoadable = React.lazy(() => import("./courses/Courses"));
const SelfStudyCoursesLoadable = React.lazy(() => import("./selfStudyCourses/Courses"));
const MyWorksLoadable = React.lazy(() => import("./myworks/Myworks"));
const IxlLoadable = React.lazy(() => import("./Ixl"));
const ProgramsLoadable = React.lazy(() => import("./programs/Programs"));
const ChildAccountsLoadable = React.lazy(() => import("./ChildAccounts"));
const QuizzesLoadable = React.lazy(() => import("./quizes/Quizzes"));
const AssignmentsLoadable = React.lazy(() => import("./assignment/Assignment"));
const AssessmentsLoadable = React.lazy(() => import("./assessment/Assessment"));
const WeightageCategoriesLoadable = React.lazy(() => import("./weightageCategory/WeightageCategory"));
const GradingSchemaLoadable = React.lazy(() => import("./gradingSchema/GradingSchema"));
const GradesLoadable = React.lazy(() => import("./grades/Grades"));
const AdHocLecturesLoadable = React.lazy(() => import("./AdHocList"));
const LibraryLoadable = React.lazy(() => import("./Library"));
const InvitationsLoadable = React.lazy(() => import("./schools/Invitations"));
const LecturesLoadable = React.lazy(() => import("./lectures/Lectures"));
const WalletLoadable = React.lazy(() => import("./Wallet"));
const NotificationsLoadable = React.lazy(() => import("./Notifications"));
const PublisherContentLoadable = React.lazy(() => import("./publisherContent/PublisherContent"));
const AdminLoadable = React.lazy(() => import("./admin/Admin"));
const ChatLoadable = React.lazy(() => import("./Chat"));
const MarketLoadable = React.lazy(() => import("./market/Market"));
const BrowseLoadable = React.lazy(() => import("./browse/Browse"));
const SharedLoadable = React.lazy(() => import("./Shared"));
const SchoolsLoadable = React.lazy(() => import("./schools"));
const TeachersLoadable = React.lazy(() => import("./teachers"));
const StudentsLoadable = React.lazy(() => import("./students"));
const TermsLoadable = React.lazy(() => import("./infoPages/Terms"));
const PrivacyLoadable = React.lazy(() => import("./infoPages/Privacy"));
const PaymentTermsLoadable = React.lazy(() => import("./infoPages/PaymentTerms"));
const CustomPageLoadable = React.lazy(() => import("./customPage/CustomPage"));

// SMS
const MainDashboardLoadable = React.lazy(() => import("./mainDashboard"));
const SubjectsLoadable = React.lazy(() => import("./sms/subjects/Subjects"));
const SubjectCurriculumsLoadable = React.lazy(() => import("./sms/subject-curriculums/SubjectCurriculums"));
const TeacherSubjectsLoadable = React.lazy(() => import("./sms/teacher-subjects/TeacherSubjects"));
const ClassesLoadable = React.lazy(() => import("./sms/classes/Classes"));
const LessonsLoadable = React.lazy(() => import("./sms/lessons/Lessons"));
const StudentAttendancesLoadable = React.lazy(() => import("./sms/student-attendances/StudentAttendances"));
const TeacherAttendancesLoadable = React.lazy(() => import("./sms/teacher-attendances/TeacherAttendances"));
const SchoolBuildingsLoadable = React.lazy(() => import("./sms/school-buildings/SchoolBuildings"));
const PeriodsLoadable = React.lazy(() => import("./sms/periods/Periods"));
const SchedulesLoadable = React.lazy(() => import("./sms/schedules/Schedules"));
const SmsStudentsLoadable = React.lazy(() => import("./sms/students/Students"));
const FeeTypesLoadable = React.lazy(() => import("./sms/fee-types/FeeTypes"));
const FeesLoadable = React.lazy(() => import("./sms/fees/Fees"));
const SchoolWeekdaysLoadable = React.lazy(() => import("./sms/school-weekdays/SchoolWeekdays"));

const IndexLoader = props => (
    <Suspense fallback={<Loading />}>
        <IndexLoadable {...props} />
    </Suspense>
);
const RegisterLoader = () => (
    <Suspense fallback={<Loading />}>
        <RegisterLoadable />
    </Suspense>
);
const LoginLoader = () => (
    <Suspense fallback={<Loading />}>
        <LoginLoadable />
    </Suspense>
);
const ProfileLoader = props => (
    <Suspense fallback={<Loading />}>
        <ProfileLoadable {...props} />
    </Suspense>
);
const CoursesLoader = () => (
    <Suspense fallback={<Loading />}>
        <CoursesLoadable />
    </Suspense>
);
const SelfStudyCoursesLoader = () => (
    <Suspense fallback={<Loading />}>
        <SelfStudyCoursesLoadable />
    </Suspense>
);
const MyWorksLoader = () => (
    <Suspense fallback={<Loading />}>
        <MyWorksLoadable />
    </Suspense>
);

const IXLLoader = () => (
    <Suspense fallback={<Loading />}>
        <IxlLoadable />
    </Suspense>
);
const ProgramsLoader = () => (
    <Suspense fallback={<Loading />}>
        <ProgramsLoadable />
    </Suspense>
);
const ChildAccountsLoader = () => (
    <Suspense fallback={<Loading />}>
        <ChildAccountsLoadable />
    </Suspense>
);
const ResetPasswordLoader = () => (
    <Suspense fallback={<Loading />}>
        <ResetPasswordLoadable />
    </Suspense>
);
const AdHocLecturesLoader = () => (
    <Suspense fallback={<Loading />}>
        <AdHocLecturesLoadable />
    </Suspense>
);
const QuizzesLoader = () => (
    <Suspense fallback={<Loading />}>
        <QuizzesLoadable />
    </Suspense>
);
const AssignmentsLoader = () => (
    <Suspense fallback={<Loading />}>
        <AssignmentsLoadable />
    </Suspense>
);

const AssessmentsLoader = () => (
    <Suspense fallback={<Loading />}>
        <AssessmentsLoadable />
    </Suspense>
);

const weightageCategoryLoader = () => (
    <Suspense fallback={<Loading />}>
        <WeightageCategoriesLoadable />
    </Suspense>
);

const gradingSchemaLoader = () => (
    <Suspense fallback={<Loading />}>
        <GradingSchemaLoadable />
    </Suspense>
);

const GradesLoader = () => (
    <Suspense fallback={<Loading />}>
        <GradesLoadable />
    </Suspense>
);

const LibraryLoader = () => (
    <Suspense fallback={<Loading />}>
        <LibraryLoadable />
    </Suspense>
);
const InvitationsLoader = () => (
    <Suspense fallback={<Loading />}>
        <InvitationsLoadable />
    </Suspense>
);
const LecturesLoader = () => (
    <Suspense fallback={<Loading />}>
        <LecturesLoadable />
    </Suspense>
);
const WalletLoader = () => (
    <Suspense fallback={<Loading />}>
        <WalletLoadable />
    </Suspense>
);
const NotificationsLoader = () => (
    <Suspense fallback={<Loading />}>
        <NotificationsLoadable />
    </Suspense>
);
const ContentLoader = props => (
    <Suspense fallback={<Loading />}>
        <PublisherContentLoadable {...props} />
    </Suspense>
);
const AdminLoader = props => (
    <Suspense fallback={<Loading />}>
        <AdminLoadable {...props} />
    </Suspense>
);
const ChatLoader = () => (
    <Suspense fallback={<Loading invisible={true} />}>
        <ChatLoadable />
    </Suspense>
);
const MarketLoader = props => (
    <Suspense fallback={<Loading />}>
        <MarketLoadable {...props} />
    </Suspense>
);
const BrowseLoader = props => (
    <Suspense fallback={<Loading />}>
        <BrowseLoadable {...props} />
    </Suspense>
);
const SharedLoader = () => (
    <Suspense fallback={<Loading />}>
        <SharedLoadable />
    </Suspense>
);
const SchoolsLoader = () => (
    <Suspense fallback={<Loading />}>
        <SchoolsLoadable />
    </Suspense>
);

const TeachersLoader = props => (
    <Suspense fallback={<Loading />}>
        <TeachersLoadable {...props} />
    </Suspense>
);

const StudentsLoader = props => (
    <Suspense fallback={<Loading />}>
        <StudentsLoadable {...props} />
    </Suspense>
);

const TermsLoader = () => (
    <Suspense fallback={<Loading />}>
        <TermsLoadable />
    </Suspense>
);

const PrivacyLoader = () => (
    <Suspense fallback={<Loading />}>
        <PrivacyLoadable />
    </Suspense>
);

const PaymentTermsLoader = () => (
    <Suspense fallback={<Loading />}>
        <PaymentTermsLoadable />
    </Suspense>
);

const CustomPageLoader = props => (
    <Suspense fallback={<Loading />}>
        <CustomPageLoadable {...props} />
    </Suspense>
);

// SMS

const MainDashboardLoader = () => {
    return (
        <Suspense fallback={<Loading />}>
            <MainDashboardLoadable />
        </Suspense>
    );
};

const SubjectsLoader = () => {
    return (
        <Suspense fallback={<Loading />}>
            <SubjectsLoadable />
        </Suspense>
    );
};

const SubjectCurriculumsLoader = () => {
    return (
        <Suspense fallback={<Loading />}>
            <SubjectCurriculumsLoadable />
        </Suspense>
    );
};

const TeacherSubjectsLoader = () => {
    return (
        <Suspense fallback={<Loading />}>
            <TeacherSubjectsLoadable />
        </Suspense>
    );
};

const ClassesLoader = () => {
    return (
        <Suspense fallback={<Loading />}>
            <ClassesLoadable />
        </Suspense>
    );
};

const LessonsLoader = () => {
    return (
        <Suspense fallback={<Loading />}>
            <LessonsLoadable />
        </Suspense>
    );
};

const StudentAttendancesLoader = () => {
    return (
        <Suspense fallback={<Loading />}>
            <StudentAttendancesLoadable />
        </Suspense>
    );
};

const TeacherAttendancesLoader = () => {
    return (
        <Suspense fallback={<Loading />}>
            <TeacherAttendancesLoadable />
        </Suspense>
    );
};

const SchoolBuildingsLoader = () => {
    return (
        <Suspense fallback={<Loading />}>
            <SchoolBuildingsLoadable />
        </Suspense>
    );
};

const PeriodsLoader = () => {
    return (
        <Suspense fallback={<Loading />}>
            <PeriodsLoadable />
        </Suspense>
    );
};

const SchedulesLoader = () => {
    return (
        <Suspense fallback={<Loading />}>
            <SchedulesLoadable />
        </Suspense>
    );
};

const SmsStudentsLoader = () => {
    return (
        <Suspense fallback={<Loading />}>
            <SmsStudentsLoadable />
        </Suspense>
    );
};

const FeeTypesLoader = () => {
    return (
        <Suspense fallback={<Loading />}>
            <FeeTypesLoadable />
        </Suspense>
    );
};

const FeesLoader = () => {
    return (
        <Suspense fallback={<Loading />}>
            <FeesLoadable />
        </Suspense>
    );
};

const SchoolWeekdaysLoader = () => {
    return (
        <Suspense fallback={<Loading />}>
            <SchoolWeekdaysLoadable />
        </Suspense>
    );
};

const ScrollbarContainer = styled.div`
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
`;

const Container = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;

    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
`;

@withContextConsumer(CurrentUser.Consumer)
class PageContainer extends Component {
    constructor(props) {
        super(props);
        this.scrollbarRef = React.createRef();
    }

    onScroll(e) {
        if (this.content && this.content.onScroll) {
            this.content.onScroll(e);
        }
    }

    createChatWidget() {
        let widgetExists = false;
        const iframes = document.getElementsByTagName("iframe");
        for (let i = 0; i < iframes.length; i++) {
            if ((iframes[i].title = "chat widget")) {
                iframes[i].style.visibility = "visible";
                widgetExists = true;
            }
        }

        if (!widgetExists) {
            TawkChat.init(this.props.currentUser);
        }
    }

    componentDidMount() {
        this.createChatWidget();
    }

    render() {
        const props = {
            forwardedRef: ref => (this.content = ref),
            scrollbarRef: this.scrollbarRef
        };

        return (
            <ScrollbarContainer>
                <Scrollbars scrollRef={this.scrollbarRef} onScroll={this.onScroll.bind(this)}>
                    <LandingContext.Provider>
                        <Container>
                            <Header scrollbar={this.content} {...props} />
                            <Switch>
                                <Route path={routes.home.path} component={() => <IndexLoader {...props} />} exact />
                                <Route path={routes.admin.path} component={() => <AdminLoader {...props} />} />

                                <Route
                                    path={routes.meetingDigest.path}
                                    component={props => <LecturesDigest {...props} />}
                                    exact
                                />

                                <Route path={routes.signup.path} component={RegisterLoader} />
                                <Route path={routes.signin.path} component={LoginLoader} />
                                <Route path={routes.profile.path} component={() => <ProfileLoader {...props} />} />
                                <Route path={routes.courses.path} component={CoursesLoader} />
                                <Route path={routes.selfStudycourses.path} component={SelfStudyCoursesLoader} />
                                <Route path={routes.myWork.path} component={MyWorksLoader} />
                                {/* <Route path={routes.ixl.path} component={IXLLoader} /> */}
                                <Route path={routes.programs.path} component={ProgramsLoader} />
                                <Route path={routes.meetings.path} component={AdHocLecturesLoader} />

                                <Route path={routes.children.path} component={ChildAccountsLoader} />
                                <Route path={routes.reset.path} component={ResetPasswordLoader} exact />
                                <Route path={routes.quizzes.path} component={QuizzesLoader} />
                                <Route path={routes.assignments.path} component={AssignmentsLoader} />
                                <Route path={routes.assessments.path} component={AssessmentsLoader} />
                                <Route path={routes.weightageCategory.path} component={weightageCategoryLoader} />
                                <Route path={routes.gradingSchema.path} component={gradingSchemaLoader} />
                                <Route path={routes.grades.path} component={GradesLoader} />
                                <Route path={routes.library.path} component={LibraryLoader} />
                                <Route path={routes.invitations.path} component={InvitationsLoader} />
                                <Route path={routes.lectures.path} component={LecturesLoader} />
                                <Route path={routes.wallet.path} component={WalletLoader} />
                                <Route path={routes.notifications.path} component={NotificationsLoader} />
                                <Route path={routes.content.path} component={() => <ContentLoader {...props} />} />
                                <Route path={routes.chats.path} component={ChatLoader} />
                                <Route path={routes.market.path} component={() => <MarketLoader {...props} />} />
                                <Route path={routes.browse.path} component={() => <BrowseLoader {...props} />} />
                                <Route path={routes.shared.path} component={SharedLoader} />
                                <Route path={routes.schools.path} component={SchoolsLoader} />
                                <Route path={routes.teachers.path} component={() => <TeachersLoader {...props} />} />
                                <Route path={routes.students.path} component={() => <StudentsLoader {...props} />} />
                                <Route path={routes.terms.path} component={TermsLoader} />
                                <Route path={routes.privacy.path} component={PrivacyLoader} />
                                <Route path={routes.paymentTerms.path} component={PaymentTermsLoader} />

                                {/* SMS Routes */}
                                <Route path={routes.mainDashboard.path} component={MainDashboardLoader} />
                                <Route path={routes.subjects.path} component={SubjectsLoader} />
                                <Route path={routes.subjectCurriculums.path} component={SubjectCurriculumsLoader} />
                                <Route path={routes.teacherSubjects.path} component={TeacherSubjectsLoader} />
                                <Route path={routes.classes.path} component={ClassesLoader} />
                                <Route path={routes.lessons.path} component={LessonsLoader} />
                                <Route path={routes.studentAttendances.path} component={StudentAttendancesLoader} />
                                <Route path={routes.teacherAttendances.path} component={TeacherAttendancesLoader} />
                                <Route path={routes.schoolBuildings.path} component={SchoolBuildingsLoader} />
                                <Route path={routes.periods.path} component={PeriodsLoader} />
                                <Route path={routes.schedules.path} component={SchedulesLoader} />
                                <Route path={routes.smsStudents.path} component={SmsStudentsLoader} />
                                <Route path={routes.feeTypes.path} component={FeeTypesLoader} />
                                <Route path={routes.fees.path} component={FeesLoader} />
                                <Route path={routes.schoolWeekdays.path} component={SchoolWeekdaysLoader} />

                                <Route path={routes.customPage.path} component={() => <CustomPageLoader {...props} />} />
                            </Switch>
                            <ChatWidget />
                            <Footer {...props} />
                            <GlobalModals />
                            <GlobalToast />
                        </Container>
                    </LandingContext.Provider>
                </Scrollbars>
            </ScrollbarContainer>
        );
    }
}

export default PageContainer;
